import CustomizerReducer from './customizer/CustomizerSlice';
import UserDataReducer from './user/reducer';
import CommonReducer from './common/reducer';
import NotificationReducer from './notification/reducer';

export const reducerStore = {
  customizer: CustomizerReducer,
  userData: UserDataReducer,
  common: CommonReducer,
  notification: NotificationReducer,
};
