import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
export default {
  name: "Notification",
  dir: "Notification",
  pathRoot: "notification",
  routes: [
    {
      url: ":id",
      component: "Page/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Cập nhật thông báo | HitaCamp",
        titleI18n: "Notification:update",
      },
    },
    {
      url: "",
      component: "Page/index",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thông báo | HitaCamp",
        titleI18n: "Notification:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
};
