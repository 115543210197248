import API from '@configs/api';
import apiMethod from '@utility/ApiMethod';

export const getNotificationClient = async ({ ...payload }) => {
  try {
    const { data } = await apiMethod.post(`${API.MST_NOTIFICATION_LIST_CLIENT}`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const countNotification = async ({ ...payload }) => {
  try {
    const { data } = await apiMethod.get(`${API.MST_NOTIFICATION_COUNT}`, { params: payload });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const pushNotification = async (payload) => {
  try {
    const { data } = await apiMethod.post(`${API.MST_NOTIFICATION_PUSH}`, payload);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateNotification = async (eventId) => {
  try {
    const { data } = await apiMethod.put(`${API.MST_NOTIFICATION_UPDATE}`, { eventId });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const detailNotification = async ({ id, ...rest }) => {
  try {
    const { data } = await apiMethod.get(`${API.MST_NOTIFICATION_DETAIL}/${id}`, { params: rest });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const detailAdminNotification = async (payload) => {
  try {
    const { data } = await apiMethod.get(`${API.MST_NOTIFICATION_DETAIL_ADMIN}`, {
      params: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteNotificationByAdmin = async (payload) => {
  try {
    const { data } = await apiMethod.delete(`${API.MST_NOTIFICATION_DElETE_ADMIN}`, {
      data: payload,
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListNotification = async (payload) => {
  try {
    const { data } = await apiMethod.post(`${API.MST_NOTIFICATION_LIST}`, { payload });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
