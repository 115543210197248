import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'CheckLuckyDrawPublic',
  dir: 'CheckLuckyDrawPublic',
  pathRoot: 'btc-checkluckydraw',
  routes: [
    {
      url: ':eventId',
      component: 'Page/index',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'CheckLuckyDrawPublic:title',
        headerStyle: 'fill',
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: 'Any',
};
