import { createSlice } from '@reduxjs/toolkit';
import { getProfileUser } from './services';

const initialState = {
  userData: {},
  loading: false,
  error: undefined,
  isImpersonation: false,
  impersonationData: {},
};

export const UserDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserError: (state, action) => {
      state.error = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = state.isImpersonation
        ? state.userData
        : { ...state.userData, ...action.payload };
      state.impersonationData = state.isImpersonation
        ? { ...state.impersonationData, ...action.payload }
        : {};
    },
    setImpersonationData: (state, action) => {
      state.impersonationData = action.payload;
    },
    setIsImpersonationData: (state, action) => {
      state.isImpersonation = action.payload;
    },
  },
});

export default UserDataSlice.reducer;
