import API from '@configs/api';
import apiMethod from '@utility/ApiMethod';

export const getProfileUser = async ({ ...payload }) => {
  try {
    const { data } = await apiMethod.get(`${API.GET_USER_DETAIL}`, { params: payload });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const impersonationApi = async (eventId) => {
  try {
    const { data } = await apiMethod.post(`${API.IMPERSONATION}`, { eventId });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
