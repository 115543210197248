import { createSlice } from '@reduxjs/toolkit';
import { getProfileNotification } from './services';

const initialState = {
  loading: false,
  error: undefined,
  notifications: [],
  count: 0,
};

export const NotificationDataSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNotificationError: (state, action) => {
      state.error = action.payload;
    },
    setListNotification: (state, action) => {
      state.notifications = action.payload;
    },
    setCountNotification: (state, action) => {
      state.count = action.payload;
    },
  },
});

export default NotificationDataSlice.reducer;
