import React, { lazy, useEffect, useState } from 'react';
/*Store state Redux Saga */
/*Hooks*/
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeSettings } from './theme/Theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import RTL from '@layouts/components/shared/customizer/RTL';
import { getLanguageSelector } from '@store/common/selector';
import { initApp } from '@utility/AppProvider';
import locale from 'antd/es/locale/vi_VN';
import localeEn from 'antd/es/locale/en_US';
import ConfigProvider from 'antd/es/config-provider';
import emptyMatBao from '@assets/images/backgrounds/matbao-404.svg';
import Empty from 'antd/es/empty';

//** Load App
const LazyApp = lazy(() => import('./App'));

const InitApp = ({ DefaultRoute, listRoutes, listNav }) => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { i18n } = useTranslation();

  const theme = ThemeSettings();
  const lang = useSelector(getLanguageSelector);
  const customizer = useSelector((state) => state?.customizer);

  useEffect(() => {
    if (!!lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  useEffect(() => {
    const setupInitApp = async () => {
      setLoaded(true);
    };
    setupInitApp().then();
  }, []);

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description={i18n.t('CORE:empty_desc')} image={emptyMatBao} />}
      direction={customizer?.activeDir}
      locale={lang === 'vi' ? locale : localeEn}
    >
      <ThemeProvider theme={theme}>
        <RTL direction={customizer?.activeDir}>
          <CssBaseline />
          <LazyApp DefaultRoute={DefaultRoute} Routes={listRoutes} Nav={listNav} />
        </RTL>
      </ThemeProvider>
    </ConfigProvider>
  );
};
export default InitApp;
