import { createSelector } from 'reselect';

export const Common = (state) => state.common;

export const getLanguageSelector = createSelector(Common, (data) => {
  return data?.language ?? 'vi';
});

export const getPinSelector = createSelector(Common, (data) => {
  return data?.pin ?? null;
});

export const getStatusTesting = createSelector(Common, (data) => {
  return data?.statusTesting ?? null;
});
