const ENV_ENVIRONMENT_BASE_URL_API = 'https://api.webdep102.com/api';

const ENV_ENVIRONMENT_awsKeys = {
  bucket: 'smart-events-dev',
  accessKeyID: 'AKIAS37NMZ7DOYPGLTM6',
  secretKeyID: 'U8FMmXTyUcCk/4s8xDvtRJ3pn5yxiQPtjTl81MoK',
  endpoint: 'https://smart-events-dev.s3.ap-southeast-1.amazonaws.com/',
  region: 'ap-southeast-1',
};

export const ENVIRONMENT_BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API;
export const ENVIRONMENT_awsKeys = ENV_ENVIRONMENT_awsKeys;

export const ENV_PUBLIC_API = 'https://api.webdep102.com/api'; 

