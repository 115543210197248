import { ENVIRONMENT_BASE_URL_API, ENV_PUBLIC_API } from './environment';
export const BASE_URL_API = ENVIRONMENT_BASE_URL_API;
export const PUBLIC_API = ENV_PUBLIC_API;

export default {
  GET_USER_DETAIL: '/MstUserAdmin/detail',
  IMPERSONATION: '/MstUserAdmin/impersonation',

  MST_TEST_EVENT_CREATE: '/MstTestEvent/create',
  MST_TEST_EVENT_DELETE: '/MstTestEvent/delete',
  MST_TEST_EVENT_CHECK_TESTING: '/MstTestEvent/check-testing',

  MST_NOTIFICATION_LIST_CLIENT: '/MstNotifications/list-by-eventId',
  MST_NOTIFICATION_COUNT: '/MstNotifications/count-unread-by-eventId',
  MST_NOTIFICATION_PUSH: '/MstNotifications/push',
  MST_NOTIFICATION_UPDATE: '/MstNotifications/update',
  MST_NOTIFICATION_DETAIL: '/MstNotifications/detail',
  MST_NOTIFICATION_DETAIL_ADMIN: '/MstNotifications/detail-admin',
  MST_NOTIFICATION_DElETE_ADMIN: '/MstNotifications/delete-admin',
  MST_NOTIFICATION_LIST: '/MstNotifications/list',

  CHECK_EVENT_DELETE: '/MstEvent/check-event-delete',
  GROUP_INVITE_DETAIL: '/MstGroupInvite/detail',
};
