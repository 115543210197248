import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'Event',
  dir: 'Event',
  pathRoot: 'event',
  routes: [
    {
      url: 'edit/:id',
      component: 'Page/TabEdit',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Cập nhật sự kiện | Smart Event',
        titleI18n: 'Event:updateTab',
      },
    },
    {
      url: ':id',
      component: 'Page/CreateEdit',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Cập nhật sự kiện | Smart Event',
        titleI18n: 'Event:updateTab',
      },
    },
    {
      url: '',
      component: 'Page/index',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'Event:title',
        headerStyle: 'fill',
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  roles: ["superadmin"],
};
