export default [
  { key: 'Authenticate' },
  { key: 'Event' },
  { key: 'Public' },
  { key: 'CheckInOutPublic' },
  { key: 'CheckLuckyDrawPublic' },
  { key: 'Smtp' },
  { key: 'CheckInOut' },
  { key: 'LuckyDraw' },
  { key: 'InfoPin' },
  { key: 'InfoPrizesLuckydraw' },
  { key: 'InfoEventPayment' },
  { key: 'GroupInvite' },
  { key: 'UserRegister' },
  { key: 'UserInvite' },
  { key: 'Notification' },
  { key: 'Account' },
  { key: 'NotificationClient' },
  { key: 'DynamicGroupCode' },
  { key: 'DynamicOption' },
  { key: 'Gallery' },
  { key: 'Home' },
  { key: 'SendMailHistory'}
];
